/*
 * @Author: zhaoyang
 * @Date: 2022-11-24 15:40:20
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-09-04 15:04:18
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'ssr-common/constant/config';

const api = {
    getHost: getEurekaHost,

    getCustomerOnline: {
        url: '/api/cashloan/mexico/customerServiceUrl',
        method: 'get'
    },

    generalConfig: {
        url: '/api/generalConfig',
        method: 'get'
    },

    getStaticTextByKey: {
        url: '/api/operation/staticText/getByKey',
        method: 'get'
    }
};

export default Resource.create(api);
