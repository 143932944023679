var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "help-center" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "sub-routes" },
        _vm._l(_vm.QuestionTypeMap, function (text, type) {
          return _c(
            "router-link",
            {
              directives: [
                {
                  name: "click-ripple",
                  rawName: "v-click-ripple",
                  value: { disabled: _vm.$route.params.type === type },
                  expression: "{disabled: $route.params.type === type}",
                },
              ],
              key: type,
              staticClass: "sub-route",
              attrs: { to: { name: "question", params: { type: type } } },
            },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "\n                    " + _vm._s(text) + "\n                "
                ),
              ]),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("li", { staticClass: "hot-issue" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.$t("webview.hotIssue")) + "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "middle-container" },
      [
        _c(
          "transition",
          { attrs: { name: "fade" } },
          [_c("router-view", { key: _vm.$route.params.type })],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isBottomVisible
      ? _c("div", [
          _c("div", { staticClass: "bottom-placeholder" }),
          _vm._v(" "),
          _c("div", { staticClass: "bottom-container" }, [
            _c(
              "div",
              {
                directives: [
                  { name: "click-ripple", rawName: "v-click-ripple" },
                ],
                staticClass: "bottom-btn",
                on: { click: _vm.openOnlineCustomer },
              },
              [
                _c(
                  "div",
                  { staticClass: "text" },
                  [
                    _c("antd-icon", {
                      staticClass: "btn-icon",
                      attrs: { type: "service-o" },
                    }),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("webview.helpCenter.onlineService")) +
                        "\n                "
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  { name: "click-ripple", rawName: "v-click-ripple" },
                ],
                staticClass: "bottom-btn",
                on: { click: _vm.callCustomerServiceTel },
              },
              [
                _c(
                  "div",
                  { staticClass: "text" },
                  [
                    _c("antd-icon", {
                      staticClass: "btn-icon",
                      attrs: { type: "telephone-o" },
                    }),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("webview.helpCenter.telService")) +
                        "\n                "
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }