<!-- @author ruiwang -->
<!-- @email ruiwang@yangqianguan.com -->
<!-- @date 2019-12-18 13:51:51 -->
<!-- @desc index.vue -->

<template>
    <div class="help-center">
        <div class="content">
            <div class="sub-routes">
                <router-link
                    v-for="(text, type) in QuestionTypeMap"
                    :key="type"
                    v-click-ripple="{disabled: $route.params.type === type}"
                    :to="{name: 'question', params: {type}}"
                    class="sub-route"
                >
                    <div class="text">
                        {{ text }}
                    </div>
                </router-link>
            </div>
            <li class="hot-issue">
                {{ $t('webview.hotIssue') }}
            </li>
        </div>
        <div class="middle-container">
            <transition name="fade">
                <router-view :key="$route.params.type" />
            </transition>
        </div>
        <div v-if="isBottomVisible">
            <div class="bottom-placeholder" />
            <div class="bottom-container">
                <div
                    v-click-ripple
                    class="bottom-btn"
                    @click="openOnlineCustomer"
                >
                    <div class="text">
                        <antd-icon
                            type="service-o"
                            class="btn-icon"
                        />
                        {{ $t('webview.helpCenter.onlineService') }}
                    </div>
                </div>
                <div
                    v-click-ripple
                    class="bottom-btn"
                    @click="callCustomerServiceTel"
                >
                    <div class="text">
                        <antd-icon
                            type="telephone-o"
                            class="btn-icon"
                        />
                        {{ $t('webview.helpCenter.telService') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import CustomerOnline from 'ssr-common/resource/online-customer';
import {isOlacred} from 'ssr-common/util/webview/ua';
import webviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import parseUa from 'ssr-common/vue/mixin/parse-ua';
import routerPush from 'ssr-common/vue/mixin/router-push';

export default {
    name: 'HelpCenter',

    mixins: [parseUa(), routerPush],

    data() {
        return {
            QuestionTypeMap: this.$t('webview.helpCenter.questionTypeMap')
        };
    },

    title() {
        return this.$t('webview.helpCenter.title');
    },

    computed: {
        isBottomVisible() {
            return isOlacred(this.ua);
        }
    },

    methods: {
        callCustomerServiceTel() {
            setTimeout(() => {
                webviewInterface.callCustomerServiceTel();
            }, 300);
        },

        async openOnlineCustomer() {
            const {data: {body}} = await CustomerOnline.getCustomerOnline();
            if (!body) return;
            this.routerPush({link: body});
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $white: #fff;
    $bg-color: #f5fbfb;
    $text-color: #49495c;
    $border-color: #eee;

    .help-center {
        position: relative;
        min-height: 100vh;
        background-color: $bg-color;
        box-sizing: border-box;

        .content {
            position: sticky;
            top: 0;
            z-index: 1;
            padding: 10px 0;
            left: 0;
            background-color: #f5fbfb;
        }

        .icon-right {
            position: absolute;
            top: 42px;
            right: 30px;
            width: 12px;
            transform: rotate(0);
            transition: transform 0.3s;
            color: #dedede;
        }

        .logo-us {
            width: 24px;
            height: 24px;
        }

        .hot-issue {
            margin: 20px 0 10px;
            font-size: 16px;
        }

        .switch-sign {
            font-size: 14px;
            font-weight: 800;
        }

        .sub-routes {
            position: sticky;
            top: 0;
            padding: 10px 20px;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-radius: 10px;
            background-color: $white;
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 6%);

            .sub-route {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 47%;
                padding: 10px 0;
                margin: 10px 0;
                color: $white;
                font-size: 14px;
                text-decoration: none;
                user-select: none;
                border-radius: 8px;
                background-color: #a3acbc;
                -webkit-tap-highlight-color: transparent;

                &.router-link-active {
                    background-color: #ff734b;
                }

                .text {
                    text-align: center;
                    line-height: 1;
                }
            }
        }

        .middle-container {
            overflow-y: scroll;
            max-height: calc(100vh - 240px);
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 6%);
            border-radius: 10px;

            ::v-deep .ec-expand-box {
                padding: 0;
            }
        }

        .bottom-placeholder {
            height: 60px;
        }

        .bottom-container {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            background-color: $white;
            border-top: 1px solid $border-color;

            .bottom-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 50%;
                color: $text-color;
                font-size: 14px;
                text-decoration: none;
                user-select: none;

                &:first-child {
                    border-right: 1px solid $border-color;
                }

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .btn-icon {
                        margin-right: 3px;
                        width: 24px;
                        height: 24px;
                        color: #ccc;
                    }
                }
            }
        }

        .fade-enter-active {
            transition: all 0.5s ease 0.3s;
        }

        .fade-leave-active {
            transition: all 0.3s ease;
        }

        .fade-enter {
            opacity: 0%;
            transform: translateY(-50px);
        }

        .fade-leave-to {
            opacity: 0%;
        }
    }

</style>
