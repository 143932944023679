/*
 * @Author: yuanyuanliu
 * @Date: 2022-08-31 15:27:00
 * @Last Modified by: yuanyuanliu
 * @Last Modified time: 2022-09-01 16:03:06
 */

import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

export default {
    methods: {
        routerPush(route) {
            let href = '';
            if (typeof route === 'object' && route.link) {
                href = route.link;
            } else {
                href = this.$router.resolve(typeof route === 'string' ? {name: route} : route).href;
            }

            WebviewInterface.openWebview(href);
        }
    }
};

